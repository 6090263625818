import Swiper from "swiper/swiper-bundle.mjs";

const sliderVideos = $(".swiper-slide video");

const autoplayer = () => {
  const prevVideo = $(".swiper-slide").find("video");
  const currentVideo = $(".swiper-slide-active").find("video");

  // Reset video
  sliderVideos.each(function (index) {
    this.currentTime = 0;
  });

  // Pause other slides
  if (prevVideo.length) {
    prevVideo.get(0).pause();
  }

  // Play current slide
  if (currentVideo.length) {
    currentVideo.get(0).play();
  }
};

const announcements = new Swiper(".announcements", {
  autoplay: {
    delay: 3000,
  },
  centeredSlides: true,
  loop: true,
  slidesPerView: 1,
  effect: "fade",
  crossFade: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const hero = new Swiper(".hero", {
  autoplay: {
    delay: 14000,
  },
  centeredSlides: true,
  loop: true,
  slidesPerView: 1,
  effect: "fade",
  crossFade: true,
  init: false,
});

hero.on("afterInit slideChangeTransitionEnd", function () {
  autoplayer();
});

hero.init();

const carousel = new Swiper(".carousel", {
  centeredSlides: true,
  slidesPerView: 1.2,
  spaceBetween: 16,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    bulletClass: "block w-6 h-1 bg-current opacity-20",
    bulletActiveClass: "!opacity-100",
  },
  init: false,
});

carousel.on("afterInit slideChangeTransitionEnd", function () {
  autoplayer();
});

carousel.init();

const promoCarousel = new Swiper(".promo-carousel", {
  centeredSlides: true,
  slidesPerView: 1.2,
  spaceBetween: 16,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    bulletClass: "block w-6 h-1 bg-current opacity-20",
    bulletActiveClass: "!opacity-100",
  },
});

let bIndex = 0;

$(".horizontal-scroll").each(function () {
  bIndex++;
  $(this).attr("id", "horizontal-scroll-" + bIndex);

  const horizontalScroll = new Swiper("#horizontal-scroll-" + bIndex, {
    spaceBetween: 32,
    slidesPerView: "auto",
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      640: {
        slidesOffsetBefore: 32,
        slidesOffsetAfter: 32,
      },
      1024: {
        slidesOffsetBefore: 64,
        slidesOffsetAfter: 64,
      },
    },
  });
});

let cIndex = 0;

$(".product-horizontal-scroll").each(function (index) {
  cIndex++;
  $(this).attr("id", "product-horizontal-scroll-" + cIndex);

  const productHorizontalScroll = new Swiper(
    "#product-horizontal-scroll-" + cIndex,
    {
      spaceBetween: 16,
      slidesPerView: "auto",
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      freeMode: true,
      mousewheel: {
        forceToAxis: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesOffsetBefore: 32,
          slidesOffsetAfter: 32,
        },
        1024: {
          slidesOffsetBefore: 64,
          slidesOffsetAfter: 64,
        },
      },
    },
  );
});

const testimonials = new Swiper(".testimonials", {
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    bulletClass: "block w-6 h-1 bg-current opacity-20",
    bulletActiveClass: "!opacity-100",
  },
});

const productThumbs = new Swiper(".product-thumbs", {
  spaceBetween: 16,
  slidesPerView: "auto",
  slidesOffsetBefore: 44,
  watchSlidesProgress: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const productCarousel = new Swiper(".product-carousel", {
  loop: true,
  thumbs: {
    swiper: productThumbs,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

productCarousel.on("afterInit slideChangeTransitionEnd", function () {
  autoplayer();
});

productCarousel.init();

export {
  announcements,
  hero,
  carousel,
  promoCarousel,
  horizontalScroll,
  productHorizontalScroll,
  testimonials,
  productCarousel,
};
