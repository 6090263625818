const nav = document.querySelector("#main-navigation");

const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");

// Toggle nav
hamburger.addEventListener("click", function () {
  nav.classList.contains("hidden") ? openNav() : closeNav();
});

// Toggle megamenu
$(".openMegamenu").click(function (e) {
  if ($(window).width() <= 1080) {
    e.preventDefault();

    const submenu = $(this).next();
    submenu.hasClass("opacity-0") ? openSubnav(submenu) : "";
  }
});

$(".openSubmenu").click(function (e) {
  const submenu = $(this).next();

  if ($(window).width() <= 1080) {
    e.preventDefault();
    openSubnav(submenu);
  }
});

$(".closeSubnav").click(function () {
  const submenu = $(this).parent();
  closeSubnav(submenu);
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 1080) {
    closeNav();
  }
});

const openNav = () => {
  nav.classList.remove("hidden");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
};

const closeNav = () => {
  nav.classList.add("hidden");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
  resetSubnav();
  closeSubnav($(".megamenu"));
};

const resetSubnav = () => {
  $(".subnav").addClass("opacity-0 translate-x-full");
};

const openSubnav = (elem) => {
  resetSubnav();
  elem.removeClass("opacity-0 translate-x-full");
};

const closeSubnav = (elem) => {
  elem.addClass("opacity-0 translate-x-full");
};

export { openNav, closeNav };
