/*
 * Menu widget:
 * https://www.algolia.com/doc/api-reference/widgets/menu/js/
 */

import instantsearch from "instantsearch.js";
import { panel, menu } from "instantsearch.js/es/widgets";

const availabilityPanel = panel({
  templates: {
    header: " ",
    collapseButtonText(options, { html }) {
      return html`
        <span
          class="group flex justify-between items-center gap-x-6 w-80 lg:w-full py-3 px-6 lg:px-0"
        >
          <span class="font-medium">Availability</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            class="block w-4 h-4 fill-black/20 group-hover:fill-black ${options.collapsed
              ? "rotate-90"
              : "-rotate-90"} transition"
          >
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
            />
          </svg>
        </span>
      `;
    },
  },
  hidden(options) {
    return !options.canRefine;
  },
  collapsed: () => false,
  cssClasses: {
    root: "border-b border-black/10",
    body: "mb-6 px-6 lg:px-0",
    collapseButton: "w-full",
  },
})(menu);

export const availability = $("[data-widget='availability']").length
  ? availabilityPanel({
      container: '[data-widget="availability"]',
      attribute: "availability",
      cssClasses: {
        item: "radio mt-1 text-base font-medium",
        selectedItem: "selected font-medium",
        count: "hidden",
      },
    })
  : null;
