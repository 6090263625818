/*
 * Search box widget:
 * https://www.algolia.com/doc/api-reference/widgets/search-box/js/
 */

import instantsearch from "instantsearch.js";
import { searchBox } from "instantsearch.js/es/widgets";

export const searching = $("[data-widget='search']").length
  ? searchBox({
      container: '[data-widget="search"]',
      placeholder: "Search for products",
      showSubmit: false,
      cssClasses: {
        form: "relative flex items-center max-w-max mb-4",
        input: "input w-60 bg-white",
        reset: "absolute top-0 bottom-0 right-0 w-8 m-px rounded-md bg-white",
      },
    })
  : null;
