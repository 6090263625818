import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";

// SEO friendly settings for categories
const encodedCategories = {
  // '': 'Accessories',
  // '': 'Accessories > Office Acoustics',
  // '': 'Accessories > Office Castors',
  // '': 'Accessories > Office Lighting',
  // '': 'Accessories > Office Monitor Arms',
  // '': 'Accessories > Office Rugs',
  // '': 'Accessories > Work Desk Accessories',
  // '': 'Breakout & Collaboration',
  // '': 'Breakout & Collaboration > Media Units',
  // '': 'Breakout & Collaboration > Office Armchairs',
  // '': 'Breakout & Collaboration > Office Lounge Chairs',
  // '': 'Breakout & Collaboration > Office Meeting Pods',
  // '': 'Breakout & Collaboration > Office Sofas',
  // '': 'Breakout & Collaboration > Office Stools / Benches',
  // '': 'Breakout & Collaboration > Office Tents',
  // '': 'Breakout & Collaboration > Office Whiteboards',
  // '': 'Breakout & Collaboration > Privacy Booths',
  // '': 'Desking',
  // '': 'Desking > Executive Desks',
  // '': 'Desking > Glass Office Desks',
  // '': 'Desking > Height Adjustable Desks',
  // '': 'Desking > L-Shaped Desks',
  // '': 'Desking > Office Bench Desks',
  // '': 'Desking > Office Desks',
  // '': 'Desking > WFH Desks',
  // '': 'Education',
  // '': 'Education > Classroom Chairs',
  // '': 'Education > Classroom Tables',
  // '': 'Education > Display & Presentation',
  // '': 'Education > Library Furniture',
  // '': 'Education > Mobile Classroom Furniture',
  // '': 'Reception',
  // '': 'Reception > Reception Chairs And Sofas',
  // '': 'Reception > Reception Desks',
  // '': 'Seating',
  // '': 'Seating > Bistro Chairs / Work Cafe',
  // '': 'Seating > Draughtsman Chairs',
  // '': 'Seating > Executive Meeting / Conference Chairs',
  // '': 'Seating > Executive Office Chairs',
  // '': 'Seating > In Stock Office Seating',
  // '': 'Seating > Meeting / Conference Chairs',
  // '': 'Seating > Mesh Office Chairs',
  // '': 'Seating > Office Chairs',
  // '': 'Seating > Stacking Office Chairs',
  // '': 'Seating > WFH Chairs',
  // '': 'Showroom Clearance',
  // '': 'Showroom Clearance > Office Breakout Clearance',
  // '': 'Showroom Clearance > Office Conference Clearance',
  // '': 'Showroom Clearance > Office Seating Clearance',
  // '': 'Showroom Clearance > Office Storage Clearance',
  // '': 'Showroom Clearance > Office Table Clearance',
  // '': 'Storage',
  // '': 'Storage > Office Credenzas',
  // '': 'Storage > Office Cupboards',
  // '': 'Storage > Office Lockers',
  // '': 'Storage > Office Pedestals',
  // '': 'Storage > Office Planters',
  // '': 'Storage > Office Recycling Stations',
  // '': 'Storage > Office Shelving',
  // '': 'Storage > Office Storage Walls',
  // '': 'Tables & Conference',
  // '': 'Tables & Conference > Conference Tables',
  // '': 'Tables & Conference > Height Adjustable Meeting Tables',
  // '': 'Tables & Conference > Modular / Flip Top Tables',
  // '': 'Tables & Conference > Office Coffee / Side Tables',
  // '': 'Tables & Conference > Office Laptop Tables',
  // '': 'Tables & Conference > Office Meeting Tables',
  // '': 'Tables & Conference > Poseur Height Tables',
};

const decodedCategories = Object.keys(encodedCategories).reduce((acc, key) => {
  const newKey = encodedCategories[key];
  const newValue = key;

  return {
    ...acc,
    [newKey]: newValue,
  };
}, {});

function getCategorySlug(name) {
  const encodedName = decodedCategories[name] || name;

  return encodedName.split(" > ").map(encodeURIComponent).join("+");
}

function getCategoryName(slug) {
  const decodedSlug = encodedCategories[slug] || slug;

  return decodedSlug.split("+").map(decodeURIComponent).join(" > ");
}

const router = history({
  windowTitle(routeState) {
    // Do nothing
  },
  createURL({ qsModule, routeState, location }) {
    const baseUrl = `${
      location.protocol + "//" + location.host + location.pathname
    }`;
    const categoryPath = routeState.type
      ? `${getCategorySlug(routeState.type)}`
      : "";

    const queryParameters = {};

    if (routeState.availability) {
      queryParameters.availability = routeState.availability;
    }

    if (routeState.search) {
      queryParameters.search = routeState.search;
    }

    if (routeState.type) {
      queryParameters.type = categoryPath;
    }

    if (routeState.brand) {
      queryParameters.brand = routeState.brand;
    }

    if (routeState.range) {
      queryParameters.range = routeState.range;
    }

    const queryString = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    });

    return `${baseUrl}${queryString}`;
  },
  parseURL({ qsModule, location }) {
    const {
      availability,
      search,
      type,
      brand = [],
      range = [],
    } = qsModule.parse(location.search.slice(1));

    const typeCat = getCategoryName(type ?? "");

    const allTypes = Array.isArray(typeCat)
      ? typeCat
      : [typeCat].filter(Boolean);
    const allBrands = Array.isArray(brand) ? brand : [brand].filter(Boolean);
    const allRanges = Array.isArray(range) ? range : [range].filter(Boolean);

    return {
      availability,
      search,
      type: allTypes,
      brand: allBrands,
      range: allRanges,
    };
  },
});

const stateMapping = {
  stateToRoute(uiState) {
    const indexUiState = uiState["prod_Office_Furniture"] || {};

    return {
      availability: indexUiState.menu && indexUiState.menu.availability,
      brand: indexUiState.refinementList && indexUiState.refinementList.brand,
      range: indexUiState.refinementList && indexUiState.refinementList.range,
      type:
        indexUiState.hierarchicalMenu &&
        indexUiState.hierarchicalMenu["type.lvl0"] &&
        indexUiState.hierarchicalMenu["type.lvl0"].join(" > "),
    };
  },
  routeToState(routeState) {
    return {
      ["prod_Office_Furniture"]: {
        menu: {
          availability: routeState.availability,
        },
        query: routeState.search,
        refinementList: {
          brand: routeState.brand,
          range: routeState.range,
        },
        hierarchicalMenu: {
          "type.lvl0": routeState.type,
        },
      },
    };
  },
};

const searchRouting = {
  router,
  stateMapping,
};

export default searchRouting;
