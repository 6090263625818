/*
 * Stats widget:
 * https://www.algolia.com/doc/api-reference/widgets/stats/js/
 */

import instantsearch from "instantsearch.js";
import { stats } from "instantsearch.js/es/widgets";

export const loadedResults = $("[data-widget='loadedResults']").length
  ? stats({
      container: '[data-widget="loadedResults"]',
      templates: {
        text(data, { html }) {
          let total = "";
          let loaded = data.hitsPerPage * (data.page + 1);

          if (loaded >= data.nbHits) {
            loaded = data.nbHits;
          }

          if (data.hasManyResults) {
            total += `${loaded} / ${data.nbHits} results`;
          } else if (data.hasOneResult) {
            total += `1 result`;
          }

          return html`<span>${total}</span>`;
        },
      },
      cssClasses: {
        root: "mt-3 text-center text-sm",
      },
    })
  : null;
