/*
 * Clear Refinements widget:
 * https://www.algolia.com/doc/api-reference/widgets/configure/js/
 */

import instantsearch from "instantsearch.js";
import { clearRefinements } from "instantsearch.js/es/widgets";

export const clearFilters = $("[data-widget='clearFilters']").length
  ? clearRefinements({
      container: '[data-widget="clearFilters"]',
      templates: {
        resetLabel({ hasRefinements }, { html }) {
          return html`<span>${hasRefinements ? "Clear filters" : ""}</span>`;
        },
      },
      cssClasses: {
        root: "px-6 lg:px-0",
        button: "button w-full mt-3",
        disabledButton: "hidden",
      },
    })
  : null;
