import * as nav from "./modules/navigation.js";
import * as carousel from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";
import search from "./algolia/search";
import productSearch from "./modules/autocomplete";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

// Page transition
let ignoreUnload = false;

$("a[href^=mailto]").on("click", function () {
  ignoreUnload = true;
});

window.addEventListener("beforeunload", function () {
  if (!ignoreUnload) {
    $("main, footer").addClass("opacity-0 duration-500 transition");
  }

  ignoreUnload = false;
});

// Algolia
if ($('[data-widget="products"]').length) {
  search.start();
}

let firstSearch = true;

// Watch search bar input change
$(document).on("input", "#autocomplete-0-input", function () {
  const val = $(this).val();

  // Delay to allow algolia to return results
  setTimeout(function () {
    const searchContainer = $(".aa-DetachedFormContainer");
    const results = $(".aa-Source");

    // Add the view all button but only once
    if (firstSearch) {
      searchContainer.append(
        '<a href="/office-furniture/all" class="aa-ViewAll button button-icon absolute right-4 sm:right-8 lg:right-16 bg-white"><span class="hidden sm:block">See all results</span> <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path></svg></a>',
      );
    }

    firstSearch = false;

    // Reset button state when no results
    if (!results.length) {
      $(".aa-ViewAll").remove();
      firstSearch = true;
    }

    // Set button to search value
    if (val && results.length) {
      $(".aa-ViewAll").attr("href", "/office-furniture/all?search=" + val);
    }
  }, 500);
});

// Prevent overscroll
function onClassChange(node, callback) {
  let lastClassString = node.classList.toString();

  const mutationObserver = new MutationObserver((mutationList) => {
    for (const item of mutationList) {
      if (item.attributeName === "class") {
        const classString = node.classList.toString();
        if (classString !== lastClassString) {
          callback(mutationObserver);
          lastClassString = classString;
          break;
        }
      }
    }
  });

  mutationObserver.observe(node, { attributes: true });

  return mutationObserver;
}

const body = document.body;

onClassChange(body, (observer) => {
  if (body.classList.contains("aa-Detached")) {
    openModal();
  } else {
    closeModal();
  }
});

window.addEventListener("scroll", () => {
  document.documentElement.style.setProperty(
    "--scroll-y",
    `${window.scrollY}px`,
  );
});

function openModal() {
  const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
  body.style.position = "fixed";
  body.style.top = `-${scrollY}`;
  body.style.left = "0px";
  body.style.right = "0px";
}

function closeModal() {
  const scrollY = body.style.top;
  body.style.position = "";
  body.style.top = "";
  body.style.left = "";
  body.style.right = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
}

// Algolia show filers
$(".open-filters").click(function () {
  if ($(window).width() > 1024) {
    $(".algolia-navigation").toggleClass("lg:hidden");
    $(this).find("span").toggleClass("lg:hidden lg:block");
  } else {
    $(".algolia-navigation").removeClass("-translate-x-full");
    $(".algolia-nav-overlay").removeClass("hidden");
  }
});

$(".close-filters").click(function () {
  $(".algolia-navigation").addClass("-translate-x-full");
  $(".algolia-nav-overlay").addClass("hidden");
});

document.addEventListener("click", (event) => {
  const filterNav = document.querySelector(".algolia-navigation");
  const open = document.querySelector(".open-filters");

  if (
    filterNav &&
    !filterNav.contains(event.target) &&
    !open.contains(event.target)
  ) {
    filterNav.classList.add("-translate-x-full");
    $(".algolia-nav-overlay").addClass("hidden");
  }
});

// Algolia wishlist buttons
$(document).on("submit", ".wishlist-toggle", function (e) {
  let form = $(this);
  let data = form.serialize();
  let icon = form.find("svg");
  let label = icon.next("span");

  $.ajax({
    type: "POST",
    url: "/",
    data: data,
    cache: true,
    dataType: "json",
    success: function (response) {
      if (response.items[0].action == "added") {
        // item added
        icon.html(
          '<path d="M23.974 5.564a7.306 7.306 0 0 1-.774 3.867 13.154 13.154 0 0 1-1.014 1.726L12 24 1.816 11.157A13.16 13.16 0 0 1 .8 9.431a7.315 7.315 0 0 1-.774-3.867 6.156 6.156 0 0 1 .208-1.121A6.094 6.094 0 0 1 6.013 0a5.993 5.993 0 0 1 5.91 5h.154a5.987 5.987 0 0 1 5.91-5 6.115 6.115 0 0 1 5.987 5.564Z"/>',
        );
        icon.addClass("fill-huntsOrange").removeClass("fill-black/20");
        label.text("Remove from enquiry");
      } else {
        // item removed
        icon.html(
          '<path d="M23.974 5.56c.124 1.397-.204 2.673-.776 3.864a13.147 13.147 0 0 1-1.014 1.725L12 23.983 1.816 11.149A13.147 13.147 0 0 1 .802 9.424C.231 8.233-.098 6.957.026 5.56c.035-.383.105-.757.208-1.12C.957 1.909 3.291-.001 6.013 0a5.974 5.974 0 0 1 3.355 1.025A5.997 5.997 0 0 1 11.923 5h.154A6 6 0 0 1 15.126.725 5.977 5.977 0 0 1 17.987 0c2.722-.001 5.056 1.909 5.779 4.44.103.363.174.737.208 1.12Zm-5.38 6.941c1.852-2.232 3.603-4.34 3.388-6.765a3.95 3.95 0 0 0-.28-1.102C21.104 3.114 19.628 2 17.988 2A4.001 4.001 0 0 0 14 5.875v.902L13.776 7h-3.552L10 6.777v-.902A4.001 4.001 0 0 0 6.012 2c-1.64 0-3.115 1.114-3.714 2.634-.139.35-.245.716-.279 1.102-.216 2.425 1.535 4.533 3.387 6.765.302.362 5.911 7.4 6.594 8.262.683-.862 6.292-7.9 6.594-8.262Z"/>',
        );
        icon.addClass("fill-black/20").removeClass("fill-huntsOrange");
        label.text("Add to enquiry");
      }

      $("#wishlist-counter").load(" #wishlist-counter > *");
    },
    error: function (jqXHR, textStatus, errorThrown) {
      // console.log(jqXHR);
    },
  });

  e.preventDefault();
});

// Close mini cart
$(document).on("click", ".hideMiniBasket", function () {
  $(".miniBasket").fadeOut();
});

// Accordions
$(document).on("click", ".accordion-toggle", function () {
  $(this).next().slideToggle();
  $(this).find(".accordion-icon").toggleClass("open");
});

$(".read-more-toggle").click(function () {
  $(this).prev().slideToggle();
  $(this).text() == "Read more"
    ? $(this).text("Read less")
    : $(this).text("Read more");
});

$(document).on("click", ".details-toggle", function () {
  $(this).text() == "Show details"
    ? $(this).text("Hide details")
    : $(this).text("Show details");

  $(this).next().slideToggle();
});

$(".accordion-toggle-sm").click(function () {
  if ($(window).width() < 640) {
    $(this).next().slideToggle();
    $(this).find("svg").toggleClass("rotate-90");
  }
});

$(".horizontal-accordion").click(function () {
  $(".horizontal-accordion").next().addClass("lg:hidden");
  $(".horizontal-accordion")
    .removeClass("lg:text-black")
    .addClass("lg:text-black/40");

  $(this).next().removeClass("lg:hidden");
  $(this).removeClass("lg:text-black/40").addClass("lg:text-black");
});

// Back to top
$(".btt-button").click(function () {
  $("html, body").animate({ scrollTop: 0 }, "slow");
});

// Dynamic pricing
$(document).on("change", "#productPrice", function () {
  calcPrice();
});

$(document).on("change", ".swatch", function () {
  calcPrice();
});

const calcPrice = () => {
  let price = $(".basePrice").data("price");

  // Get variant price
  if ($("#productPrice").length) {
    price = $("#productPrice option:selected").data("price");
  }

  // Get addon price
  let addons = $(".swatchGroup").find("input:checked");
  let priceIncrease = 0;

  addons.each(function () {
    priceIncrease += Number($(this).data("addons"));
  });

  // Get total price
  const totalPrice = (price += priceIncrease);
  const vatPrice = totalPrice + (totalPrice / 100) * 20;

  // Update pricing
  const currency = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(totalPrice);

  const vatCurrecny = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(vatPrice);

  $(".basePrice").html(currency + " ex.VAT");
  $(".vatPrice").html(vatCurrecny + " inc.VAT");
};

calcPrice();

// Anchor scroll offset
document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
  anchor.addEventListener("click", function (event) {
    event.preventDefault();

    const header = document.querySelector("header");
    const headerHeight = header.offsetHeight;
    const target = document.querySelector(this.getAttribute("href"));
    const targetPosition = target.getBoundingClientRect().top - headerHeight;

    window.scrollTo({
      top: targetPosition + window.pageYOffset,
      behavior: "smooth",
    });
  });
});
