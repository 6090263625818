import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import searchRouting from "./routing";

import {
  availability,
  brand,
  clearFilters,
  config,
  loadedResults,
  price,
  products,
  productType,
  range,
  sale,
  searching,
  sorting,
  totalResults,
  totalResultsButton,
} from "./widgets";

const search = instantsearch({
  searchClient: algoliasearch("1H64KSWY89", "3913397c85eb38436dcbee2c88778f3a"),
  indexName: "prod_Office_Furniture",
  routing: searchRouting,
});

// Brand page
if ($(".brand-products").length) {
  search.addWidgets([
    availability,
    clearFilters,
    config,
    loadedResults,
    price,
    products,
    productType,
    range,
    sale,
    sorting,
    totalResults,
    totalResultsButton,
  ]);
}

if ($(".range-products").length) {
  search.addWidgets([
    availability,
    clearFilters,
    config,
    loadedResults,
    price,
    products,
    productType,
    sale,
    sorting,
    totalResults,
    totalResultsButton,
  ]);
}

// Child category
if ($(".lvl1-products").length) {
  search.addWidgets([
    availability,
    brand,
    clearFilters,
    config,
    loadedResults,
    price,
    products,
    range,
    sale,
    sorting,
    totalResults,
    totalResultsButton,
  ]);
}

// Parent category
if ($(".all-products").length) {
  search.addWidgets([
    availability,
    brand,
    clearFilters,
    config,
    loadedResults,
    price,
    products,
    productType,
    range,
    sale,
    searching,
    sorting,
    totalResults,
    totalResultsButton,
  ]);
}

export default search;
