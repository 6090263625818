import instantsearch from "instantsearch.js";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";

// Create the render function
const renderInfiniteHits = (renderOptions, isFirstRender) => {
  const {
    hits,
    widgetParams,
    showPrevious,
    isFirstPage,
    showMore,
    isLastPage,
  } = renderOptions;

  if (isFirstRender) {
    const ol = document.createElement("ol");
    ol.className = widgetParams.cssClasses.list;

    const nextButton = document.createElement("button");
    nextButton.className = "next-button ";
    nextButton.className += widgetParams.cssClasses.loadMore;
    nextButton.textContent = "Show more results";

    nextButton.addEventListener("click", () => {
      showMore();
    });

    widgetParams.container.appendChild(ol);
    widgetParams.container.appendChild(nextButton);

    return;
  }

  widgetParams.container.querySelector(".next-button").disabled = isLastPage;

  const promo1 = $("#promo-1").length ? $("#promo-1")[0].innerHTML : "";
  const promo2 = $("#promo-2").length ? $("#promo-2")[0].innerHTML : "";

  widgetParams.container.querySelector("ol").innerHTML = `
    ${hits
      .map(
        (item) =>
          `
          ${
            item["__position"] == 11
              ? promo1
              : item["__position"] == 19
                ? promo2
                : ""
          }

          <li class="ais-InfiniteHits-item relative">
            <a href="/${item.uri}" class="group relative flex flex-col h-full">
              <div class="bg-gray-100 aspect-square overflow-hidden">
                <img
                  src="${item.image}"
                  width="800"
                  height="800"
                  class="w-full group-hover:scale-110 transition duration-500"
                />
              </div>

              <p class="my-3 font-medium">${item.title}</p>

              <p class="flex items-center gap-2 mt-auto">
                ${item.availability == "In Stock" ? "" : "From "}
                <span class="${
                  item.salePrice ? "line-through opacity-50" : ""
                }">
                  ${new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })
                    .format(item.price)
                    .replace(".00", "")}
                </span>

                <span class="text-huntsOrange font-medium ${
                  item.salePrice ? "" : "hidden"
                }">
                  ${new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })
                    .format(item.salePrice)
                    .replace(".00", "")}
                </span>

                <span class="px-3 py-1 bg-huntsOrange rounded-full text-white text-sm ${
                  item.salePrice ? "hidden sm:block" : "hidden"
                }">
                  ${
                    Math.round(
                      ((item.price - item.salePrice) / item.price) * 100 * 100,
                    ) / 100
                  }% off
                </span>
              </p>

              <p class="mt-1">${item.brand}</p>

              <span class="absolute top-4 right-4 px-2 bg-white rounded-full text-sm ${
                item.label ? "" : "hidden"
              }" style="color: ${item.labelColour};">${item.label}</span>
            </a>

            <form method="POST" class="wishlist-toggle">
              <input
                type="hidden"
                name="action"
                value="wishlist/items/toggle"
              />
              <input
                type="hidden"
                name="${window.csrfTokenName}"
                value="${window.csrfTokenValue}"
              />
              <input type="hidden" name="elementId" value="${item.productId}" />
              <input
                type="hidden"
                name="fields[wishlistQuantity]"
                id="quantity"
                value="1"
              />

              <button
                type="submit"
                class="group/wishlist absolute top-2 left-2 flex items-center p-2 rounded-full hover:bg-white transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-5 h-5 mr-2 ${
                    window.listItems.includes(item.productId)
                      ? "fill-huntsOrange"
                      : "fill-black/20"
                  } group-hover/wishlist:fill-huntsOrange transition"
                >
                  <path
                    d="${
                      window.listItems.includes(item.productId)
                        ? "M23.974 5.564a7.306 7.306 0 0 1-.774 3.867 13.154 13.154 0 0 1-1.014 1.726L12 24 1.816 11.157A13.16 13.16 0 0 1 .8 9.431a7.315 7.315 0 0 1-.774-3.867 6.156 6.156 0 0 1 .208-1.121A6.094 6.094 0 0 1 6.013 0a5.993 5.993 0 0 1 5.91 5h.154a5.987 5.987 0 0 1 5.91-5 6.115 6.115 0 0 1 5.987 5.564Z"
                        : "M23.974 5.56c.124 1.397-.204 2.673-.776 3.864a13.147 13.147 0 0 1-1.014 1.725L12 23.983 1.816 11.149A13.147 13.147 0 0 1 .802 9.424C.231 8.233-.098 6.957.026 5.56c.035-.383.105-.757.208-1.12C.957 1.909 3.291-.001 6.013 0a5.974 5.974 0 0 1 3.355 1.025A5.997 5.997 0 0 1 11.923 5h.154A6 6 0 0 1 15.126.725 5.977 5.977 0 0 1 17.987 0c2.722-.001 5.056 1.909 5.779 4.44.103.363.174.737.208 1.12Zm-5.38 6.941c1.852-2.232 3.603-4.34 3.388-6.765a3.95 3.95 0 0 0-.28-1.102C21.104 3.114 19.628 2 17.988 2A4.001 4.001 0 0 0 14 5.875v.902L13.776 7h-3.552L10 6.777v-.902A4.001 4.001 0 0 0 6.012 2c-1.64 0-3.115 1.114-3.714 2.634-.139.35-.245.716-.279 1.102-.216 2.425 1.535 4.533 3.387 6.765.302.362 5.911 7.4 6.594 8.262.683-.862 6.292-7.9 6.594-8.262Z"
                    }"
                  />
                </svg>

                <span
                  class="hidden sm:block text-sm text-huntsOrange opacity-0 group-hover/wishlist:opacity-100 transition"
                  >${
                    window.listItems.includes(item.productId)
                      ? "Remove from"
                      : "Add to"
                  } enquiry</span
                >
              </button>
            </form>
          </li>`,
      )
      .join("")}
  `;
};

export const injectedInfiniteHits = connectInfiniteHits(renderInfiniteHits);
