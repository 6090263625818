/*
 * Sort By widget:
 * https://www.algolia.com/doc/api-reference/widgets/sort-by/js/
 */

import instantsearch from "instantsearch.js";
import { sortBy } from "instantsearch.js/es/widgets";

export const sorting = $("[data-widget='sorting']").length
  ? sortBy({
      container: '[data-widget="sorting"]',
      items: [
        { label: "Featured", value: "prod_Office_Furniture" },
        {
          label: "Price (low to high)",
          value: "prod_Office_Furniture_price_asc",
        },
        {
          label: "Price (high to low)",
          value: "prod_Office_Furniture_price_desc",
        },
      ],
      cssClasses: {
        root: "w-full sm:w-auto pl-4 sm:pl-0",
        select: "select font-medium",
      },
    })
  : null;
