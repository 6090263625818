/*
 * Stats widget:
 * https://www.algolia.com/doc/api-reference/widgets/stats/js/
 */

import instantsearch from "instantsearch.js";
import { stats } from "instantsearch.js/es/widgets";

let prevTotal = 0;
let isFirstFilter = false;

export const totalResults = $("[data-widget='totalResults']").length
  ? stats({
      container: '[data-widget="totalResults"]',
      templates: {
        text(data, { html }) {
          let total = "";

          if (data.hasManyResults) {
            total += `${data.nbHits} products`;
          } else if (data.hasOneResult) {
            total += `1 product`;
          } else {
            total += `no products`;
          }

          // See if the number of results changed so we can determine a filter was pressed
          if (`${data.nbHits}` != prevTotal && isFirstFilter == true) {
            const offset = $(".products-grid").offset().top;
            const filterBar = $(".filter-nav-bar").height();
            window.scrollTo(0, offset - filterBar - 24);
          }

          isFirstFilter = true;
          prevTotal = `${data.nbHits}`;

          return html`<span>${total}</span>`;
        },
      },
    })
  : null;
