/*
 * Infinite Hits widget:
 * https://www.algolia.com/doc/api-reference/widgets/infinite-hits/js/
 */

import instantsearch from "instantsearch.js";
import { injectedInfiniteHits } from "../injected-content.js";

export const products = $("[data-widget='products']").length
  ? injectedInfiniteHits({
      container: document.querySelector('[data-widget="products"]'),
      cssClasses: {
        list: "grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,_minmax(270px,_1fr))] 2xl:grid-cols-4 gap-x-3 gap-y-12 mb-12",
        item: "relative",
        loadMore: "button mx-auto",
        disabledLoadMore: "hidden",
      },
    })
  : null;
