/*
 * Configure widget:
 * https://www.algolia.com/doc/api-reference/widgets/configure/js/
 */

import instantsearch from "instantsearch.js";
import { configure } from "instantsearch.js/es/widgets";

export const config = configure({
  filters: window.categoryFilterName,
});
