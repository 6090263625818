/*
 * Stats widget:
 * https://www.algolia.com/doc/api-reference/widgets/stats/js/
 */

import instantsearch from "instantsearch.js";
import { stats } from "instantsearch.js/es/widgets";

export const totalResultsButton = $("[data-widget='totalResultsButton']").length
  ? stats({
      container: '[data-widget="totalResultsButton"]',
      templates: {
        text(data, { html }) {
          let total = "";

          if (data.hasManyResults) {
            total += `${data.nbHits} products`;
          } else if (data.hasOneResult) {
            total += `1 product`;
          } else {
            total += `no products`;
          }

          return html`<span>${total}</span>`;
        },
      },
    })
  : null;
