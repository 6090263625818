/*
 * Toggle Refinement widget:
 * https://www.algolia.com/doc/api-reference/widgets/toggle-refinement/js/
 */

import instantsearch from "instantsearch.js";
import { toggleRefinement } from "instantsearch.js/es/widgets";

export const sale = $("[data-widget='sale']").length
  ? toggleRefinement({
      container: '[data-widget="sale"]',
      attribute: "sale",
      templates: {
        labelText({ isRefined }, { html }) {
          return html`<span class="my-3">On Sale</span>`;
        },
      },
      cssClasses: {
        root: "py-3 px-6 lg:px-0",
        checkbox:
          "appearance-none inline-block flex-shrink-0 w-3 h-3 mt-1 mr-2 border border-black/20 rounded-full checked:border-4 checked:border-black",
        labelText: "text-base font-medium cursor-pointer",
      },
    })
  : null;
