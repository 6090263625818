import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";

import "@algolia/autocomplete-theme-classic";

const searchClient = algoliasearch(
  "1H64KSWY89",
  "3913397c85eb38436dcbee2c88778f3a",
);

const productSearch = autocomplete({
  container: "#autocomplete",
  placeholder: "Search for products",
  getSources({ query }) {
    return [
      {
        sourceId: "autocompleteResults",
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: "prod_Office_Furniture",
                query,
                params: {
                  hitsPerPage: 12,
                  attributesToSnippet: ["name:10", "brand:10", "longDesc:35"],
                  snippetEllipsisText: "…",
                },
              },
            ],
          });
        },
        templates: {
          item({ item, components, html }) {
            return html`
              <a
                href="/${item.uri}"
                class="group relative flex flex-col h-full"
              >
                <div class="bg-gray-100 aspect-square overflow-hidden">
                  <img
                    src="${item.image}"
                    width="800"
                    height="800"
                    class="w-full group-hover:scale-110 transition duration-500"
                  />
                </div>

                <p class="my-3 font-medium">${item.title}</p>

                <p class="flex items-center gap-2 mt-auto">
                  ${item.availability == "In Stock" ? "" : "From "}
                  <span
                    class="${item.salePrice ? "line-through opacity-50" : ""}"
                  >
                    ${new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })
                      .format(item.price)
                      .replace(".00", "")}
                  </span>

                  <span
                    class="text-huntsOrange font-medium ${item.salePrice
                      ? ""
                      : "hidden"}"
                  >
                    ${new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })
                      .format(item.salePrice)
                      .replace(".00", "")}
                  </span>

                  <span
                    class="px-3 py-1 bg-huntsOrange rounded-full text-white text-sm ${item.salePrice
                      ? ""
                      : "hidden"}"
                  >
                    ${Math.round(
                      ((item.price - item.salePrice) / item.price) * 100 * 100,
                    ) / 100}%
                    off
                  </span>
                </p>

                <p class="mt-1">${item.brand}</p>

                <span
                  class="absolute top-4 right-4 px-2 bg-white rounded-full text-sm ${item.label
                    ? ""
                    : "hidden"}"
                  style="color: ${item.labelColour};"
                  >${item.label}</span
                >
              </a>
            `;
          },
        },
      },
    ];
  },
  onSubmit({ state }) {
    const path =
      "https://www.huntsoffice.co.uk/office-furniture/all?search=" +
      state.query;
    window.location.assign(path);

    if ($(".all-products").length) {
      setInstantSearchUiState({ query: state.query });
    }
  },
  classNames: {
    detachedSearchButton: "block w-5 h-full py-2",
    detachedSearchButtonIcon: "text-black/40 hover:text-black transition",
    detachedSearchButtonPlaceholder: "hidden",
    detachedSearchButtonQuery: "hidden",
    detachedOverlay:
      "fixed top-16 nav:top-28 bottom-0 bg-black/70 left-0 w-full z-10 overflow-y-auto",
    detachedContainer: "bg-white pt-6",
    detachedFormContainer: "container flex items-center justify-center pb-6",
    panel: "container pb-6",
    detachedCancelButton: "hidden",
    form: "relative flex items-center",
    input: "input w-60 bg-white",
    submitButton: "hidden",
    clearButton: "absolute top-0 bottom-0 right-0 w-8 m-px rounded-md bg-white",
    list: "grid grid-cols-12 gap-x-3 gap-y-12 mb-12",
    item: "col-span-6 lg:col-span-3 relative",
  },
});

export default productSearch;
